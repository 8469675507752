<template>
    <div class="card-producto">
        <figure class="card-producto__figure bg-whitesmoke mr-2 my-0">
            <img :src="producto.imagen" alt="" class="card-producto__img" style="width:90px; height: 90px;" />
        </figure>
        <div class="card-producto__detalles">
            <p class="card-producto__titulo text-general f-14 tres-puntos">{{ producto.nombre }} </p>
            <p class="card-producto__presentacion f-12">{{ producto.presentacion }}</p>
            <div class="d-flex aling-items-center justify-content-between">
                <div class="card-producto__precio-descuento text-start">
                    <div class="d-flex align-items-center">
                        <p class="card-producto__precio text-general f-12 f-500 mr-3">{{ producto.promocion ? convertMoneyTendero(producto.data_promocion.promo_valor,$tienda.idm_moneda) : convertMoneyTendero(producto.precio,$tienda.idm_moneda) }}</p>
                        <p v-if="producto.promocion" class="card-producto__precio_descuento f-12 f-500">{{ convertMoneyTendero(producto.precio,$tienda.idm_moneda) }}</p>
                    </div>
                    <p v-if="producto.promocion" class="card-producto__descuento bg-leeche cr-pointer">{{ producto.data_promocion.texto }}</p>
                </div>
                <div>
                    <el-input-number
                    v-model="producto.cantidad"
                    :min="parseFloat(producto.cantidad_minima)"
                    :step="parseFloat(producto.cantidad_minima)"
                    :step-strictly="entero"
                    :precision="precision"
                    size="mini"
                    class="card-producto__contador "
                    />
                    <p class="card-producto__titulo text-general f-12 tres-puntos">{{ agregarSeparadoresNumero(producto.stock_disponible) }} {{ producto.unidad }}. Stock</p>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        producto: {
            type: Object,
            default: () => {
                return { }
            }
        },

    },
    data(){
        return {
            tipo: 'libre',
            num: 1,
            // agregar: false,

        }
    },
    computed:{
        entero(){
            if(this.producto.cantidad_tipo === 1){
                return true
            }else{
                return false
            }
        },
        precision(){
            if(this.producto.cantidad_tipo === 1){
                return 0
            }else{
                return 2
            }
        }

    },
    methods: {

    }
}
</script>

<style lang="scss" scoped>
     .card-producto{
       // width: 365px;
        height: 89px;
        display: flex;
        align-items: center;
        margin:  8px;
        &__img{
            object-fit: contain;
        }
        &__figure{
            width: 89px;
            height: 89px;
            display: flex;
            justify-content: center;
            align-items: center;
            border-radius: 12px;
        }
        &__detalles{
            text-align: start;
            padding: 5px;
            width: 250px;
        }
        &__titulo{
            margin: 0 !important;
            word-break: break-word;
        }
        &__presentacion{
            color: #707070;
        }
        &__descuento{
            border-radius: 22px;
            font-size: 11px;
            color: #fff;
            height: 17px;
            text-align: center;
            text-transform: uppercase;
            padding:  2px 10px 0;
            width: fit-content;
        }
        &__contador{
            width: 85px !important;
        }
        &__precio_descuento{
            color: #dee2e6;
            text-decoration: line-through;
        }
    }
</style>

